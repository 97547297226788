import HttpService from "./HttpService"

class UserService extends HttpService {
  getUserData() {
    return this.get("/customer/data")
  }
  getNaturalPerson(req?: any) {
    return this.get("/api/natural-person", {}, null, req)
  }
  getResume(cv_id?: number) {
    return this.get(`/api/natural-person/cv/${cv_id}/download`, {}, null, null, "blob")
  }

  uploadResume(data: any) {
    return this.post("/api/natural-person/cv/upload", data)
  }

  deleteResume() {
    return this.delete("/api/natural-person/cv")
  }
}

export default new UserService()
