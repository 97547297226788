import { optionLanguageLevels } from "src/types/naturalProfileFormTypesAndValidation"

export const displayLanguageValueKa = (language: string, API_LANGUAGES: any) => {
  if (!API_LANGUAGES) return language
  return API_LANGUAGES.find((o: any) => o.title_en?.toLowerCase() === language.toLowerCase())?.title ?? language
}
export const displayLanguageLevelKa = (level: string) => {
  return optionLanguageLevels.find((o: any) => o.value === level)?.label ?? level
}

interface Language {
  id: number
  title: string
  title_ru: string
  title_en: string
  order: number
  status: number
}

export const getLanguageTitles = (languagesList?: Language[], languageIds?: (number | string)[]): string[] => {
  if (!languagesList || !languageIds) return []

  return languageIds.map(languageId => {
    if (typeof languageId === "number") {
      const language = languagesList?.find(lang => lang.id === languageId)
      return language ? language.title : ""
    } else if (typeof languageId === "string") {
      const language = languagesList?.find(lang => lang.title_en === languageId)
      return language ? language.title : ""
    }
    return ""
  })
}

// Function to check if title exists in value
export const filterAlreadySelectedLanguages = (title: any, objArray: any) => {
  // Iterate over each object in the second array
  for (const obj of objArray) {
    // Check if the title of obj1 exists in obj's value
    if (obj.language === title) {
      return true // Return true if found
    }
  }

  return false // Return false if not found in any object's value
}
