import * as yup from "yup"

// Personal Information for Wizard
export interface PersonalInformation {
  name: string
  surname: string
  profession: string
  industry_id?: number | null | string
  industry?: string | null
  gender: string
  birth_date: string
}

const personalInformationSchema = {
  name: yup.string().required("სახელი აუცილებელია"),
  surname: yup.string().required("გვარი აუცილებელია"),
  profession: yup.string().required("პროფესია აუცილებელია"),
  birth_date: yup.string().required("დაბადების თარიღი აუცილებელია"),
  gender: yup.string().required("სქესი აუცილებელია"),
  industry_id: yup.string().required("ინდუსტრია აუცილებელია"),
  industry: yup.string().when("industry_id", {
    is: "10000", // when industry_id is exactly 100
    then: () => yup.string().required("ინდუსტრია აუცილებელია"), // industry is required
    otherwise: () => yup.string().notRequired() // not required otherwise
  })
}

export const peresonalInformationWizardSchema = yup.object().shape({
  ...personalInformationSchema
})

export const personalInformationProfileSchema = yup.object().shape({
  ...personalInformationSchema,
  country: yup.number().required("ქვეყანა აუცილებელია"),
  city: yup.number().nullable().required("ქვეყანა აუცილებელია")
})

// Personal Information for Profile
export interface IPersonalInformationProflePayload extends PersonalInformation {
  country: number | string
  city: number | null
}

export interface Certificate {
  title: string
  establishment: string
  issue_date?: string | null
}
export const CertificateValidationSchema = yup.object().shape({
  title: yup.string().required("სერტიფიკატი აუცილებელია"),
  establishment: yup.string().required("ორგანიზაციის დასახელება აუცილებელია"),
  issue_date: yup.string().nullable()
})

export interface Education {
  establishment: string
  degree: string
  direction: string
  start_date?: string | null
  end_date?: string | null
}
export const EducationValidationSchema = yup.object().shape({
  establishment: yup.string().required("უნივერსიტეტი აუცილებელია"),
  degree: yup.string().required("ხარისხი აუცილებელია"),
  direction: yup.string().required("ფაკულტეტი აუცილებელია"),
  start_date: yup
    .string()
    .nullable()
    .when("end_date", {
      is: (end_date: any) => !!end_date,
      then: () => yup.string().required("თუ დასრულების თარიღი შეყვანილია, დაწყების თარიღიც აუცილებელია"),
      otherwise: () => yup.string().nullable()
    }),
  end_date: yup.string().nullable("")
})

export interface Training {
  title: string
  establishment: string
  start_date?: string | null
  end_date?: string | null
}

export const TrainingValidationSchema = yup.object().shape({
  title: yup.string().required("ტრენინგის დასახელება აუცილებელია"),
  establishment: yup.string().required("ორგანიზაცია აუცილებელია"),
  start_date: yup
    .string()
    .nullable()
    .when("end_date", {
      is: (end_date: any) => !!end_date,
      then: () => yup.string().required("თუ დასრულების თარიღი შეყვანილია, დაწყების თარიღიც აუცილებელია"),
      otherwise: () => yup.string()
    }),
  end_date: yup.string().nullable()
})
export type ITrainingPayload = yup.InferType<typeof TrainingValidationSchema>
//export interface of Training validation schema

export enum LanguageLevelsEnum {
  Native = "native",
  Fluent = "fluent",
  Intermediate = "intermediate",
  Good = "good",
  Beginner = "beginner"
}

export const optionLanguageLevels = [
  { id: 1, label: "მშობლიური", value: LanguageLevelsEnum.Native },
  { id: 2, label: "ძალიან კარგი", value: LanguageLevelsEnum.Fluent },
  { id: 3, label: "კარგი", value: LanguageLevelsEnum.Good },
  { id: 4, label: "საშუალო", value: LanguageLevelsEnum.Intermediate },
  { id: 5, label: "დამწყები", value: LanguageLevelsEnum.Beginner }
]
export interface Language {
  level: LanguageLevelsEnum
  language: string
}
export const LanguageValidationSchema = yup.object().shape({
  level: yup.mixed<LanguageLevelsEnum>().oneOf(Object.values(LanguageLevelsEnum)).required("დონე აუცილებელია"),
  language: yup.string().required("ენა აუცილებელია")
})

export interface Address {
  country: number
  city: number | null
  postal_code?: string | null
  address?: string | null
}
export const AddressValidationSchema = yup.object().shape({
  country: yup.string().required("ქვეყანა აუცილებელია")
  // city: yup.string().required('ქალაქი აუცილებელია')
  // postalCode: yup.string().required('საფოსტო კოდი აუცილებელია'),
  // address: yup.string().required('მისამართი აუცილებელია')
})

export interface Contact {
  mobile: string
  email: string
  show_contact_info: boolean
}
export const ContactValidationSchema = yup.object().shape({
  mobile: yup
    .string()
    // .matches(/^5\d{8}$/, 'ნომერი უნდა იწყებოდეს 5 - იანით და იყოს ზუსტად 9 ციფრი')
    .required("მობილური აუცილებელია"),
  email: yup.string().email("ელფოსტის მისამართის ფორმატი არასწორია").required("ელფოსტის მისამართი აუცილებელია"),
  show_contact_info: yup.boolean().required("აუცილებელია")
})

export interface WorkExperience {
  position: string
  company: string
  employment_type?: string | null
  job_type?: string | null
  start_date: string
  end_date?: string | null
  industry_id: number | string
  industry?: string | null
  description?: string | null
  current_position?: boolean | null
}
export const WorkExperienceValidationSchema = yup.object().shape({
  position: yup.string().required("პოზიცია აუცილებელია"),
  company: yup.string().required("კომპანია აუცილებელია"),
  employment_type: yup.string().nullable(),
  job_type: yup.string().nullable(),
  industry_id: yup.string().required("ინდუსტრია აუცილებელია"),
  industry: yup
    .string()
    .nullable()
    .when("industry_id", {
      is: 100, // when industry_id is exactly 100
      then: () => yup.string().required("ინდუსტრია აუცილებელია"), // industry is required
      otherwise: () => yup.string().nullable() // not required otherwise
    }),

  start_date: yup.string().required("დაწყების თარიღი აუცილებელია"),
  end_date: yup.string().when("current_position", {
    is: true, // when current_position is true
    then: () => yup.string().nullable(), // make end_date not required
    otherwise: () => yup.string().required("დასრულების თარიღი აუცილებელია") // otherwise, make it required
  }),

  current_position: yup.boolean().nullable(),
  description: yup.string().nullable()
})

// export const SkillsSoftAndTechnicalValidationSchema = yup.object().shape({
//   skill: yup
//     .string()
//     .test('len', 'მინიმუმ 2 ასო', val => val && val.length >= 2)
//     .required('აუცილებელია'),
//   soft_skill: yup.string().test('len', 'მინიმუმ 2 ასო', val => val && val.length >= 2),
//   skills: yup.array(),
//   soft_skills: yup.array()
// })

export const SkillsValidationSchema = yup.object().shape({
  skill: yup
    .string()
    // .test('len', 'მინიმუმ 2 ასო', val => (typeof val === 'string' ? val.length >= 2 : false))
    .required("აუცილებელია")
})

export const SoftSkillsValidationSchema = yup.object().shape({
  soft_skill: yup
    .string()
    // .test('len', 'მინიმუმ 2 ასო', val => (typeof val === 'string' ? val.length >= 2 : false))
    .required("აუცილებელია")
})

export interface SocialLink {
  link: string
  type?: string | null
}
export interface AdditionalInformation {
  citizenship?: string
  driving_licenses?: string[]
  current_salary?: number
  hobbies?: string
  about_me?: string
  disabled_person: boolean // This field is required in your schema
  links?: SocialLink[]
  link?: string
}
export const AdditionalInformationValidationSchema = yup.object().shape({
  citizenship: yup.string().optional(),
  driving_licenses: yup.array().of(yup.string()).optional(),
  current_salary: yup
    .number()
    .nullable()
    .transform((_, originalValue) => (originalValue === "" ? null : Number(originalValue)))
    .optional(),
  hobbies: yup.string().optional(),
  about_me: yup.string().optional(),
  disabled_person: yup.boolean().required(),
  links: yup
    .array()
    .of(
      yup.object().shape({
        link: yup.string().url().required()
      })
    )
    .nullable()
})
// export const linkValidationSchema = yup.object({
//   link: yup
//     .string()
//     .matches(/^(https:\/\/|www\.|google\.com$)/, 'ჩაწერეთ ვალიდური ბმული (მაგ: https://www.example.com)')
//     .required('ბმული აუცილებელია') // Required field
// })

export const linkValidationSchema = yup.object({
  link: yup
    .string()
    // The regex is applied only if there is some non-empty input
    .matches(/^(https:\/\/|www\.|google\.com$)/, {
      message: "ჩაწერეთ ვალიდური ბმული (მაგ: https://www.example.com)",
      excludeEmptyString: true // Ensures the regex is not applied to an empty string
    })
})

export const ProfileValidationSchema = yup.object().shape({
  user_name: yup.string().required("სავალდებულო"),
  user_surname: yup.string().required("სავალდებულო"),
  birth_year: yup.number().required("სავალდებულო"),
  phone: yup.string().required("სავალდებულო"),
  email: yup.string().required("სავალდებულო"),
  gender_id: yup.number().required("სავალდებულო"),
  otp: yup.number()
})

export interface Profile {
  user_name: string
  user_surname: string
  birth_year: number
  phone: string
  email: string
  gender_id: number
  otp?: number
}

export const PasswordValidationSchema = yup.object().shape({
  current_password: yup.string().required("სავალდებულო"),
  password: yup.string().required("სავალდებულო"),
  password_check: yup
    .string()
    .required("სავალდებულო")
    .oneOf([yup.ref("password"), ""], "პაროლები არ ემთხვევა ერთმანეთს")
})

export interface Password {
  current_password: string
  password: string
  password_check: string
}
